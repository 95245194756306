import { computed, Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  user = signal<User | null>(null);
  isLoggedIn = computed(
    () => !!this.user()?.email || !!localStorage.getItem('access_token')
  );
  userInfo = computed(() => {
    const user = this.user();
    if (user) {
      return user;
    }
    const token = localStorage.getItem('access_token');
    const payload = token ? JSON.parse(atob(token.split('.')[1])) : null;
    return {
      email: payload?.email,
      role: payload?.role,
    };
  });
}

export interface User {
  email: string;
  role: string;
}
